import React from "react";
import Countdown from "react-countdown";
import logo from "../assets/images/logo_test.png";

const style = { color: "#21baef", cursor: "pointer" };

const Completionist = () => (
  <div id="loader" className="loader">
    <div style={{ color: "white", margin: "auto" }}>
      <img src={logo} alt="default" width={150} height={35} />
      <br />
      <br />
      <div>
        <h4>We could not load the page you are looking for.</h4>
        <br />
        <h4>
          This can be caused by a connectivity issue or the page does not exist.
        </h4>
        <h4>
          Go to{" "}
          <span style={style} onClick={() => (window.location = "/")}>
            Home Page{" "}
          </span>
          .
        </h4>
      </div>
    </div>
  </div>
);

const renderer = ({ completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <div id="loader" className="loader">
        <span>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </span>
      </div>
    );
  }
};

function Loader() {
  return <Countdown date={Date.now() + 30000} renderer={renderer} />;
}

export default Loader;
