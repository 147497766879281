import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import upload from "../assets/images/upload.png";
import Loader from "../elements/Loader";
import Footer from "../elements/Footer";
import Navbar from "../elements/Navbar";

import { connect } from "react-redux";
import { selectLoadingStatus } from "../redux/users/users.selector";
import { usernameUpdateAsync } from "../redux/users/users.action";
import { createStructuredSelector } from "reselect";
import { getCurrentUser as user } from "../services/authServices";
import {
  getUser,
  updateProfilePicture,
  removeProfilePicture,
} from "../services/usersService";
import { getCardToken } from "../services/paymentServices";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import "../stylesheets/profile.css";

const maxPictureSize = 1000000; //1MB

function Profile({ loading, usernameUpdateAsync }) {
  const [src, setSrc] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [picture, setPicture] = useState("");
  const [data, setData] = useState({});
  const [username, setUsername] = useState("");
  const [aboutme, setAboutme] = useState("");
  const [newname, setNewname] = useState("");
  const [card, setCard] = useState("");
  const currentUser = user();

  const toggleOpen = useCallback(() => setOpen((prev) => !prev), []);

  const handlePictureSelect = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    if (selectedFile.size > maxPictureSize) {
      toast.error("The picture selected is too large, try another.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const fileURL = URL.createObjectURL(selectedFile);
    setSrc(fileURL);
    setPicture(selectedFile);
  };

  const updateUsername = async () => {
    try {
      if (username.trim().length > 0) {
        await usernameUpdateAsync({ username });
      }
      if (aboutme.trim().length > 0) {
        localStorage.setItem("aboutme", aboutme);
        toast.success("Your About Me successfully updated.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error("Failed to update username.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const uploadPicture = async () => {
    if (!picture) {
      toast.error("No picture selected.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", picture, `${currentUser?.email}.png`);
      const result = await updateProfilePicture(formData);
      localStorage.setItem("profileURL", result.data.fileLocation);
      setSrc(false);
      toast.success("Profile picture successfully updated.", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    } catch (error) {
      toast.error("Failed to update profile picture.", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };

  const deleteImage = async () => {
    try {
      setLoading(true);
      toggleOpen();
      await removeProfilePicture();
      localStorage.removeItem("profileURL");
      localStorage.setItem("profileURL", data?.profileURL);
      toast.success("Profile picture successfully deleted.", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    } catch (error) {
      if (error.response.status === 404) toast.error(error.response.data);
      else
        toast.error("Failed to delete profile picture", {
          position: toast.POSITION.TOP_CENTER,
        });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async function () {
      const { data } = await getUser(currentUser?.email);
      const { data: cardData } = await getCardToken();
      setNewname(data);
      setCard(cardData);
    })();
    if (!currentUser) {
      window.location = "/";
      return;
    }
    const data = currentUser;
    setData(data);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <div className="container profile">
            <div className="row">
              {!isMobile && <div className="col"></div>}
              <div className={`${isMobile ? "" : "col-8"}`}>
                <h3>Profile Settings</h3>
                <br />
                <div className="innercontainer">
                  <h3 className="title">Manage Profile</h3>

                  {/* profile row starts */}
                  <div className="row">
                    <div className="col profilecircle">
                      <div>
                        <div className="circle">
                          <img
                            className="profile-pic proimg"
                            src={
                              src ||
                              localStorage.getItem("profileURL") ||
                              data.profileURL
                            }
                            alt="Profile"
                          />
                        </div>
                        <div className="p-image" style={{ marginTop: "-30px" }}>
                          <label htmlFor="image">
                            <img
                              src={upload}
                              alt=""
                              width="30px"
                              height="30px"
                              className="proimg"
                            />
                          </label>
                          <input
                            type="file"
                            id="image"
                            onChange={handlePictureSelect}
                            hidden
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="d-grid  mt-4">
                        {src ? (
                          <button
                            className={`btn btn-primary btn-sm imageBtn`}
                            onClick={uploadPicture}
                          >
                            Change Profile Image
                          </button>
                        ) : (
                          <button
                            className={`btn btn-danger btn-sm imageBtn`}
                            onClick={toggleOpen}
                          >
                            Delete Profile Image
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="col"></div>
                  </div>

                  {/* profile row ends */}
                  <hr className="hrcolor" />

                  {/* profile username */}

                  <div className="row">
                    <div className="col hrcolor">
                      <h6>Username</h6>
                    </div>
                    <div className="col">
                      <input
                        name="username"
                        type="text"
                        placeholder={newname?.username}
                        value={username}
                        onChange={(e) => setUsername(e.currentTarget.value)}
                      />
                    </div>
                    <div className="col">3 of 3</div>
                  </div>
                  <hr className="hrcolor" />

                  {/* profile username ends */}

                  {/* profile email starts */}
                  <div className="row">
                    <div className="col hrcolor">
                      <h6>Email</h6>
                    </div>
                    <div className="col hrcolor">
                      <h5>{data.email}</h5>
                    </div>
                    <div className="col">3 of 3</div>
                  </div>
                  <hr className="hrcolor" />
                  {/* profile email ends */}

                  {/* password starts */}

                  <div className="row">
                    <div className="col hrcolor">
                      <h6>Password</h6>
                    </div>
                    <div className="col hrcolor">
                      <h5>•••••••••••</h5>
                    </div>
                    <div className="col accentcol">
                      <Link to="/changepassword">Change Password</Link>
                    </div>
                  </div>
                  <hr className="hrcolor" />
                  {/* password ends */}

                  {/* about me starts */}

                  {/* <hr className="hrcolor" /> */}

                  <div className="row">
                    <div className="col hrcolor">
                      <h6>Payment Card</h6>
                    </div>
                    <div className="col hrcolor">
                      <h5>
                        {card.tokenPayload
                          ? "************" + card?.tokenPayload?.last4Digits
                          : "No card details provided yet"}
                      </h5>
                    </div>
                    <div className="col accentcol">
                      {card.tokenPayload ? (
                        <Link to="/paymentdetails">Payment Settings</Link>
                      ) : null}
                    </div>
                  </div>

                  <hr className="hrcolor" />

                  <div className="row">
                    <div className="col hrcolor">
                      <h6>About Me</h6>
                    </div>
                    <div className="col hrcolor">
                      <textarea
                        name="aboutme"
                        column="20"
                        maxLength={120}
                        placeholder={localStorage.getItem("aboutme")}
                        value={aboutme}
                        onChange={(e) => setAboutme(e.currentTarget.value)}
                      />
                    </div>
                    <div className="col"></div>
                  </div>
                  <hr className="hrcolor" />
                  {/* about me ends */}

                  {/* button starts */}
                  <div className="row">
                    <div className="col"></div>
                    <div className="col hrcolor">
                      <div className="d-grid  mt-4">
                        <button
                          className={`btn btn-primary btn-sm imageBtn ${
                            (username.length < 3 || username.length > 16) &&
                            aboutme.length < 3 &&
                            "disabled"
                          }`}
                          onClick={updateUsername}
                        >
                          Save Profile details
                        </button>
                      </div>
                    </div>
                    <div className="col"></div>
                  </div>
                  {/* button ends */}
                </div>
              </div>
              {!isMobile && <div className="col"></div>}
            </div>
          </div>
          <Footer />
          <Dialog
            open={open}
            onClose={toggleOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Profile Picture Deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                style={{ color: "black" }}
              >
                Are you sure you want to delete your profile picture ? <br />
                <span style={{ fontSize: "13px", color: "black" }}>
                  After permanently deleting your profile picture, you can
                  always set a new profile picture.
                </span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button onClick={toggleOpen} className="btn btn-secondary">
                Cancel
              </button>
              <button
                onClick={deleteImage}
                className="btn btn-primary"
                autoFocus
              >
                Yes, Delete.
              </button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: selectLoadingStatus,
});

const mapDispatchToProps = (dispatch) => ({
  usernameUpdateAsync: (user) => dispatch(usernameUpdateAsync(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
