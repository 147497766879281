import React from "react";
import { Link } from "react-router-dom";
import TawkTo from "tawkto-react";

import { getCurrentUser as user } from "../../services/authServices";

const defaultProfile =
  "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png";

function DesktopNavAuth() {
  const currentUser = user();

  const profileURL = localStorage.getItem("profileURL");
  const username = localStorage.getItem("username");

  const startChat = () => {
    var tawk = new TawkTo("62377aba1ffac05b1d7f851f", "1fukbeqts");
    tawk.onStatusChange((status) => {
      alert(
        "A chat box will show up on the bottom right corner, kindly click to chat with our support team."
      );
    });
  };

  const profileImage = profileURL || currentUser?.profileURL || defaultProfile;

  return (
    <React.Fragment>
      {/* <!-- When logged out --> */}
      {!currentUser && (
        <Link to="/login" className="btn btn-primary-gradient">
          Let's Start
        </Link>
      )}

      {/* <!-- When logged in --> */}
      {currentUser && (
        <div className="dropdown">
          <Link
            to="/"
            className="
                    btn btn-default
                    text-capitalize
                    dropdown-toggle
                    d-flex
                    align-content-center align-items-center
                    font-light
                  "
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div className="profile-img-wrap me-2">
              <img src={profileImage} alt="User profile" />
            </div>
            {username || currentUser.username}
          </Link>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdownMenuButton1"
          >
            <li>
              <Link className="dropdown-item" to="/profile">
                Manage Profile
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="/watchlist">
                My Watchlist
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="/favorites">
                My Favorite Movies
              </Link>
            </li>
            <li>
              <span
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={startChat}
              >
                Contact Us
              </span>
            </li>
            {/* <li>
              <Link className="dropdown-item" to="/helpcenter">
                Help Center
              </Link>
            </li> */}
            <li>
              <Link className="dropdown-item" to="/logout">
                Logout
              </Link>
            </li>
          </ul>
        </div>
      )}
    </React.Fragment>
  );
}

export default DesktopNavAuth;
