import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { VscClose } from "react-icons/vsc";
import Logo from "../assets/images/logo_test.png";
import { Stream } from "@cloudflare/stream-react";
import Loader from "../elements/Loader";
import * as Sentry from "@sentry/react";

import { connect } from "react-redux";
import {
  fetchMoviesAsync,
  fetchOrderAsync,
} from "../redux/movies/movies.action";
import { selectOrders } from "../redux/movies/movies.selector";
import { getCurrentUser as user } from "../services/authServices";
import {
  selectVertualMovies,
  selectLoadingStatus as loading,
} from "../redux/movies/movies.selector";
import { updateOrderCurrentTime } from "../services/movieServices";
import { createStructuredSelector } from "reselect";
import VerifyToken from "../components/tokenCheck/VerifyToken";

const style = { color: "#21baef", cursor: "pointer" };

function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    const updateSize = () => setSize([window.innerWidth, window.innerHeight]);
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function MoviePlayer({
  fetchMoviesAsync,
  fetchOrderAsync,
  movies,
  loading,
  orders,
}) {
  const videoRef = useRef(null);
  const { movieid, orderid } = useParams();
  const [isLoading, setLoading] = useState(false);

  //let navigate = useNavigate();
  const handleClose = async () => {
    setLoading(true);
    try {
      await updateOrderCurrentTime({
        _id: orderid,
        currentTime: videoRef.current?.currentTime,
      });
      window.location = "/playlist";
      //navigate(-1);
    } catch (error) {
      setLoading(false);
      Sentry.captureException("MoviePlayer.jsx", error);
    }
  };

  const currentUser = user();
  const movie = movies.find((m) => m._id === movieid);
  const order = orders.find((o) => o._id === orderid);

  const [videoWidth, videoHeigth] = useWindowSize();

  useEffect(() => {
    if (!currentUser) {
      window.location = "/";
      return;
    }
    fetchMoviesAsync();
    fetchOrderAsync();
  }, [fetchMoviesAsync, fetchOrderAsync]);

  return (
    <>
      {loading || !movie || !order || isLoading ? (
        <Loader />
      ) : order?.email === currentUser?.email ? (
        <div
          style={{
            position: "relative",
            margin: "auto",
            width: videoWidth > 780 ? videoWidth - 150 : videoWidth,
            height: videoHeigth,
          }}
        >
          <div style={{ position: "absolute", zIndex: 1000 }}>
            <VscClose
              style={{ margin: "20px", cursor: "pointer" }}
              size={30}
              onClick={handleClose}
            />
          </div>
          <div>
            <Stream
              responsive
              streamRef={videoRef}
              current
              poster={movie?.movieBannerPictureURL}
              controls
              src={movie?.videoId}
              currentTime={order?.currentTime}
              startTime={order?.currentTime}
            />
            {/* <iframe
              ref={videoRef}
              src={`https://customer-havaaneo6rni3uo8.cloudflarestream.com/${movie?.videoId}/iframe?time=800&poster=${movie?.movieBannerPictureURL}`}
              loading="lazy"
              style={{
                border: "none",
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
              }}
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"
            ></iframe> */}
          </div>
        </div>
      ) : (
        <div id="loader" className="loader">
          <div style={{ color: "white", margin: "auto" }}>
            <img src={Logo} alt="default" width={150} height={35} />
            <br />
            <br />
            <div>
              <h4>You are not allowed to watch this movie.</h4>
              <br />
              <h4>
                Go to{" "}
                <span style={style} onClick={() => (window.location = "/")}>
                  Home Page{" "}
                </span>
                .
              </h4>
            </div>
          </div>
        </div>
      )}
      {currentUser && <VerifyToken />}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchMoviesAsync: () => dispatch(fetchMoviesAsync()),
  fetchOrderAsync: () => dispatch(fetchOrderAsync()),
});

const mapStateToProps = createStructuredSelector({
  movies: selectVertualMovies,
  orders: selectOrders,
  loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(MoviePlayer);
